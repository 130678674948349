<script setup lang='ts'>

</script>

<template>
    <div class="layout-main-wrapper font-primary font-normal flex flex-col flex-1">
        <div class="layout-main-container w-full pr-0 pl-0">
            <div class="layout-main">
                <slot />
            </div>
        </div>

        <AppFooter class="hidden md:block" />
    </div>
</template>

<style lang="scss" scoped>

</style>
